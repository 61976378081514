<template>
    <div class="inner-section">
      <card>
        <!-- search section start -->
        <template v-slot:searchHeaderTitle>
          <h4 class="card-title">{{ $t('national_award.application') }} {{ $t('globalTrans.search') }}</h4>
        </template>
        <template v-slot:searchBody>
          <b-row>
            <b-col xs="12" sm="6" md="3" lg="3" xl="3">
              <b-form-group
                label-for="circular_id"
              >
                <template v-slot:label>
                  {{ $t('national_award.circular') }}
                </template>
                 <b-form-select
                    id="circular_id"
                    v-model="search.circular_id"
                    :options="circularList"
                  >
                  <template v-slot:first>
                    <b-form-select-option value=''>{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                  </b-form-select>
              </b-form-group>
            </b-col>
            <b-col xs="12" sm="6" md="3" lg="3" xl="3">
              <b-form-group
                label-for="category_id"
              >
                <template v-slot:label>
                  {{ $t('national_award.category') }}
                </template>
                 <b-form-select
                    id="category_id"
                    v-model="search.category_id"
                    :options="categoryList"
                  >
                  <template v-slot:first>
                    <b-form-select-option value=''>{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                  </b-form-select>
              </b-form-group>
            </b-col>
            <b-col xs="12" sm="3" md="3" lg="3" xl="3">
              <b-button size="sm" variant="primary" class="mt-20" @click="searchData">
                <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
              </b-button>
            </b-col>
          </b-row>
        </template>
        <!-- search section end -->
      </card>
      <body-card>
        <!-- table section start -->
        <template v-slot:headerTitle>
          <h4 class="card-title"> {{ $t('national_award.application') }}  {{ $t('globalTrans.list') }}</h4>
        </template>
        <template v-slot:headerAction>
          <a
              style="color: white"
              title="Excel"
              class="btn-add btn-warning mr-1"
              :href="`${teaGardenServiceBaseUrl}btb/national-tea-award/application/approved-list/?local=${$i18n.locale}&request_type=excel&search=${encodeURIComponent(JSON.stringify(search))}`"
              target="_blank"
            >
              {{ $t('globalTrans.export_excel') }}
          </a>
          <a href="javascript:" class="btn-add mr-1" @click="pdfExport">
              {{ $t('globalTrans.export_pdf') }}
          </a>
          <div class="quick-filter-right">
          <b-form-group
                :label="$t('menu.perpage')"
                label-for="per-page-select"
              >
                <b-form-select
                  id="per-page-select"
                  v-model="search.limit"
                  :options="pageOptions"
                  size="sm"
                ></b-form-select>
              </b-form-group>
          </div>
        </template>
        <template v-slot:body>
          <b-overlay :show="loadingState">
            <b-table :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" head-variant="primary" class="tg" :items="listData" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered striped hover :fields="columns.filter(item => item.show === '1').sort((a, b) => { return a.order - b.order })">
              <template v-slot:cell(serial)="data">
                {{ $n(data.item.serial + 1) }}
              </template>
              <template v-slot:cell(category)="data">
                {{ data.item.category_id ? getCategoryName(data.item.category_id) : '' }}
              </template>
              <template v-slot:cell(applicant_mobile)="data">
                {{ data.item.applicant_mobile ? EngBangNum(data.item.applicant_mobile) : '' }}
              </template>
              <template v-slot:cell(final_mark)="data">
                {{ data.item.final_mark ? EngBangNum(data.item.final_mark) : '' }}
              </template>
              <template v-slot:cell(circular_id)="data">
                {{ getCircularName(data.item.circular_id) }}
              </template>
              <template v-slot:cell(status)="data">
                <span v-if="data.item.status == 3" class="badge badge-success">{{ $t('national_award.application_received') }}</span>
              </template>
              <template v-slot:cell(action)="data">
                <div class="d-inline">
                  <!-- application view -->
                  <router-link variant=" iq-bg-success mr-1" size="sm" :to="'national-tea-award-application/' + data.item.id" class="moc-action-btn moc-view-btn" :title="$t('globalTrans.view')"><i class="ri-eye-line"></i></router-link>
                </div>
              </template>
            </b-table>
            <b-pagination
              align="center"
              class="rjsc-pagi"
              v-model="pagination.currentPage"
              :per-page="search.limit"
              :total-rows="pagination.totalRows"
              @input="loadData"
            />
          </b-overlay>
        </template>
        <!-- table section end -->
      </body-card>
    </div>
  </template>
  <script>
  import { btbTeaAwardApplicationApprovedListApi } from '../../../api/routes'
  import ModalBaseMasterList from '@/mixins/list'
  import RestApi, { teaGardenServiceBaseUrl } from '../../../../../../config/api_config'
  export default {
    mixins: [ModalBaseMasterList],
    data () {
      return {
        teaGardenServiceBaseUrl: teaGardenServiceBaseUrl,
        selected_item: [],
        selectedItem: [],
        selectAll: false,
        feeTitle: null,
        item: null,
        feeType: 1,
        search: {
          circular_id: '',
          category_id: '',
          limit: 10
        },
        labelData: [
          { labels: 'globalTrans.sl_no', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 2, thStyle: { width: '2%' } },
          { labels: 'national_award.circular', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 3, thStyle: { width: '8%' } },
          { labels: 'national_award.category', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 4, thStyle: { width: '15%' } },
          { labels: 'teaGardenConfig.company_name', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 5, thStyle: { width: '10%' } },
          { labels: 'globalTrans.applicant_name', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 6, thStyle: { width: '10%' } },
          { labels: 'teaGardenConfig.mobile_no', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 7, thStyle: { width: '10%' } },
          { labels: 'national_award.final_result', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 8, thStyle: { width: '8%' } },
           { labels: 'globalTrans.status', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 9, thStyle: { width: '2%' } },
          { labels: 'globalTrans.action', class: 'text-center', show: '1', order: 10, thStyle: { width: '5%' } }
        ],
        actions: {
          edit: false,
          details: true,
          toogle: false,
          delete: false
        },
        sortDesc: true,
        sortDirection: 'desc',
        sortBy: '',
        pagination: {
          currentPage: 1,
          totalRows: 0,
          perPage: 10,
          slOffset: 1
        },
        detailsData: []
      }
    },
    created () {
      this.loadData()
    },
    watch: {
      'search.limit': function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.loadData()
        }
      }
    },
    computed: {
      pageOptions () {
        return this.$store.state.commonObj.pageOptions
      },
      columns () {
        const labelData = this.labelData
        const labels = labelData.map((item, index) => {
          return Object.assign(item, { label: this.$t(item.labels, this.$i18n.locale) })
        })
        let keys = []
        if (this.$i18n.locale === 'bn') {
          keys = [
            { key: 'serial' },
            { key: 'circular_id' },
            { key: 'category' },
            { key: 'company_name_bn' },
            { key: 'applicant_name_bn' },
            { key: 'applicant_mobile' },
            { key: 'final_mark' },
            { key: 'status' },
            { key: 'action' }
          ]
        } else {
          keys = [
            { key: 'serial' },
            { key: 'circular_id' },
            { key: 'category' },
            { key: 'company_name_en' },
            { key: 'applicant_name_en' },
            { key: 'applicant_mobile' },
            { key: 'final_mark' },
            { key: 'status' },
            { key: 'action' }
          ]
        }
        return labels.map((item, index) => {
          return Object.assign(item, keys[index])
        })
      },
      formTitle () {
        return (this.editItemId === 0) ? this.$t('externalUser.company_profile') + ' ' + this.$t('globalTrans.entry') : this.$t('externalUser.company_profile') + ' ' + this.$t('globalTrans.modify')
      },
      categoryList () {
        return this.$store.state.TeaGardenService.commonObj.teaAwardCategoryList.filter(item => item.status === 1)
      },
      circularList () {
        return this.$store.state.TeaGardenService.commonObj.teaAwardCircularList.filter(item => item.status === 1)
      }
    },
    methods: {
      getCategoryName (catId) {
            const cateObj = this.$store.state.TeaGardenService.commonObj.teaAwardCategoryList.find(item => item.value === catId)
            if (cateObj !== undefined) {
                if (this.$i18n.locale === 'bn') {
                    return cateObj.text_bn
                } else {
                    return cateObj.text_en
                }
            }
        },
       EngBangNum (n) {
          if (this.$i18n.locale === 'bn') {
              return n.replace(/\d/g, d => '০১২৩৪৫৬৭৮৯'[d])
          } else {
              return n.replace(/[০-৯]/g, d => '০১২৩৪৫৬৭৮৯'.indexOf(d))
          }
       },
      searchData () {
        this.loadData()
      },
      async loadData () {
        const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
        this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        const result = await RestApi.getData(teaGardenServiceBaseUrl, btbTeaAwardApplicationApprovedListApi, params)
        if (result.success) {
          if (result.data.data) {
            const listData = result.data.data.map((item, index) => {
              return Object.assign({}, item, { serial: index })
            })
            this.$store.dispatch('setList', listData)
            this.paginationData(result.data, this.search.limit)
          } else {
            this.$store.dispatch('setList', [])
            this.paginationData([])
          }
        } else {
          this.$store.dispatch('setList', [])
          this.paginationData([])
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      },

      async pdfExport () {
        const params = Object.assign({ request_type: 'pdf', local: this.$i18n.locale, org_id: 5 }, this.search)
        const service = this.$store.state.TeaGardenService.commonObj.masterServiceList.find(item => item.value === 27)
        if (service !== undefined) {
          if (service.office_type_id) {
            params.office_type_id = service.office_type_id
          }
          if (service.office_id) {
            params.office_id = service.office_id
          }
        }
        this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        const result = await RestApi.getPdfData(teaGardenServiceBaseUrl, btbTeaAwardApplicationApprovedListApi, params)
        var blob = new Blob([result], {
          type: 'application/pdf'
        })
        var url = window.URL.createObjectURL(blob)
        window.open(url).print()
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      },

      edit (data) {
        this.detailsData = data
      },
      setData (data) {
        this.selected_item = data
      },
      getCircularName (id) {
        const obj = this.circularList.find(item => item.value === id)
        if (obj) {
          return this.$i18n.locale === 'en' ? obj?.text_en : obj?.text_bn
        }
        return ''
      }
    }
  }
</script>
